import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../context/api';

const EditTimetablePage = () => {
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState('');
  const [timetableEntries, setTimetableEntries] = useState({});
  const [newEntry, setNewEntry] = useState({ item: '', startTime: '', endTime: '', day: 'Monday' });
  const [selectedDay, setSelectedDay] = useState('Monday');
  const [addingNewEntry, setAddingNewEntry] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showNoTimetableModal, setShowNoTimetableModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchClasses();
  }, []);

  const fetchClasses = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/gradelevelclasses/get-all-gradelevelclasses`);
      setClasses(response.data.classes);
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
  };

  const fetchTimetable = async () => {
    if (!selectedClass) return;
    try {
      const response = await axios.get(`${BASE_URL}/timetables/get-timetable/${selectedClass}`);
      if (Object.keys(response.data).length === 0) {
        // No timetable found for the class, show modal
        setShowNoTimetableModal(true);
      } else {
        setTimetableEntries(response.data);
      }
    } catch (error) {
      console.error('Error fetching timetable:', error);
    }
  };

  const handleEntryChange = (day, index, field, value) => {
    const updatedEntries = [...(timetableEntries[day] || [])];
    updatedEntries[index] = { ...updatedEntries[index], [field]: value };
    setTimetableEntries({ ...timetableEntries, [day]: updatedEntries });
  };

  const handleAddEntry = () => {
    if (!newEntry.item || !newEntry.startTime || !newEntry.endTime) {
      alert('All fields must be filled');
      return;
    }

    const updatedEntries = {
      ...timetableEntries,
      [selectedDay]: [
        ...(timetableEntries[selectedDay] || []),
        {
          Item: newEntry.item,
          StartTime: newEntry.startTime,
          EndTime: newEntry.endTime,
        },
      ],
    };

    setTimetableEntries(updatedEntries);
    setNewEntry({ item: '', startTime: '', endTime: '', day: 'Monday' });
    setAddingNewEntry(false);
  };

  const handleDeleteEntry = (day, index) => {
    const updatedEntries = timetableEntries[day].filter((_, idx) => idx !== index);
    setTimetableEntries({ ...timetableEntries, [day]: updatedEntries });
  };

  const handleSaveTimetable = async () => {
    if (!selectedClass) return alert('Please select a class');
    try {
      const flattenedEntries = Object.entries(timetableEntries).flatMap(([day, entries]) =>
        entries.map(entry => ({ ...entry, Day: day }))
      );

      if (flattenedEntries.length === 0) return alert('No timetable entries to save');

      await axios.post(`${BASE_URL}/timetables/edit-timetable`, {
        classID: selectedClass,
        timetableEntries: flattenedEntries,
      });

      setShowSuccessModal(true); // Show the success modal
    } catch (error) {
      console.error('Error saving timetable:', error);
      alert('Failed to save timetable');
    }
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    navigate('/view-timetable'); // Navigate to the view timetable page
  };

  const handleCloseNoTimetableModal = () => {
    setShowNoTimetableModal(false);
    setTimetableEntries({}); // Reset timetable entries
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Edit Timetable</h2>
      <div className="mb-4">
        <label className="block text-gray-700 mb-2">Select Class:</label>
        <select
          value={selectedClass}
          onChange={(e) => setSelectedClass(e.target.value)}
          className="w-full p-2 border rounded"
        >
          <option value="">Select a class</option>
          {classes.map((cls) => (
            <option key={cls.ClassID} value={cls.ClassID}>
              {cls.ClassName}
            </option>
          ))}
        </select>
      </div>
      <button
        onClick={fetchTimetable}
        className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300"
      >
        Load Timetable
      </button>

      {Object.keys(timetableEntries).length > 0 && (
        <div>
          <div className="mb-4 mt-4">
            <label className="block text-gray-700 mb-2">Select Day:</label>
            <select
              value={selectedDay}
              onChange={(e) => setSelectedDay(e.target.value)}
              className="w-full p-2 border rounded"
            >
              {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].map((day) => (
                <option key={day} value={day}>
                  {day}
                </option>
              ))}
            </select>
          </div>
          <h3 className="text-xl font-semibold mb-2 mt-4">Edit Timetable for {selectedDay}</h3>
          <table className="min-w-full bg-white border border-gray-200 rounded-lg">
            <thead>
              <tr className="bg-[#BB005A] text-white">
                <th className="py-2 px-4 text-start border-b">Item</th>
                <th className="py-2 px-4 text-start border-b">Start Time</th>
                <th className="py-2 px-4 text-start border-b">End Time</th>
                <th className="py-2 px-4 text-start border-b">Actions</th>
              </tr>
            </thead>
            <tbody>
              {(timetableEntries[selectedDay] || []).map((entry, idx) => (
                <tr key={`${selectedDay}-${idx}`}>
                  <td className="py-2 px-4 border-b">
                    <input
                      type="text"
                      value={entry.Item}
                      onChange={(e) => handleEntryChange(selectedDay, idx, 'Item', e.target.value)}
                      className="w-full p-2 border rounded"
                    />
                  </td>
                  <td className="py-2 px-4 border-b">
                    <input
                      type="time"
                      value={entry.StartTime}
                      onChange={(e) => handleEntryChange(selectedDay, idx, 'StartTime', e.target.value)}
                      className="w-full p-2 border rounded"
                    />
                  </td>
                  <td className="py-2 px-4 border-b">
                    <input
                      type="time"
                      value={entry.EndTime}
                      onChange={(e) => handleEntryChange(selectedDay, idx, 'EndTime', e.target.value)}
                      className="w-full p-2 border rounded"
                    />
                  </td>
                  <td className="py-2 px-4 border-b">
                    <button
                      onClick={() => handleDeleteEntry(selectedDay, idx)}
                      className="text-red-500"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
              {addingNewEntry && (
                <tr>
                  <td className="py-2 px-4 border-b">
                    <input
                      type="text"
                      placeholder="New Item"
                      value={newEntry.item}
                      onChange={(e) => setNewEntry({ ...newEntry, item: e.target.value })}
                      className="w-full p-2 border rounded"
                    />
                  </td>
                  <td className="py-2 px-4 border-b">
                    <input
                      type="time"
                      placeholder="New Start Time"
                      value={newEntry.startTime}
                      onChange={(e) => setNewEntry({ ...newEntry, startTime: e.target.value })}
                      className="w-full p-2 border rounded"
                    />
                  </td>
                  <td className="py-2 px-4 border-b">
                    <input
                      type="time"
                      placeholder="New End Time"
                      value={newEntry.endTime}
                      onChange={(e) => setNewEntry({ ...newEntry, endTime: e.target.value })}
                      className="w-full p-2 border rounded"
                    />
                  </td>
                  <td className="py-2 px-4 border-b">
                    <button
                      onClick={handleAddEntry}
                      className="text-green-500"
                    >
                      Save
                    </button>
                    <button
                      onClick={() => setAddingNewEntry(false)}
                      className="text-red-500 ml-2"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <button
            onClick={() => setAddingNewEntry(true)}
            className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300"
          >
            Add New Entry
          </button>
          <button
            onClick={handleSaveTimetable}
            className="mt-4 bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition duration-300"
          >
            Save Timetable
          </button>
        </div>
      )}

      {showSuccessModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg">
            <h3 className="text-lg font-semibold mb-4">Success</h3>
            <p className="mb-4">Timetable has been successfully updated.</p>
            <button
              onClick={handleCloseSuccessModal}
              className="bg-pink-500 text-white py-2 px-4 rounded hover:bg-pink-600 transition duration-300"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {showNoTimetableModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg">
            <h3 className="text-lg font-semibold mb-4">No Timetable Found</h3>
            <p className="mb-4">There is no existing timetable for this class. You can start creating one.</p>
            <button
              onClick={handleCloseNoTimetableModal}
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300"
            >
              Start Creating
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditTimetablePage;
