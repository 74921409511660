import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../context/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'tailwindcss/tailwind.css';

const Announcements = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [target, setTarget] = useState('students');
  const [date, setDate] = useState('');
  const [description, setDescription] = useState('');
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const announcementsPerPage = 10;

  useEffect(() => {
    fetchAnnouncements();
  }, []);

  const fetchAnnouncements = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/announcements/`);
      setAnnouncements(response.data);
    } catch (error) {
      console.error('Error fetching announcements:', error);
    }
  };

  const handleAddOrEdit = async () => {
    try {
      if (isEditMode) {
        await axios.put(`${BASE_URL}/announcements/${selectedAnnouncement.AnnouncementID}`, {
          Target: target,
          Date: date,
          Description: description
        });
        toast.success('Announcement updated successfully');
      } else {
        await axios.post(`${BASE_URL}/announcements/`, {
          Target: target,
          Date: date,
          Description: description
        });
        toast.success('Announcement added successfully');
      }
      setTarget('students');
      setDate('');
      setDescription('');
      setSelectedAnnouncement(null);
      setIsEditMode(false);
      fetchAnnouncements();
    } catch (error) {
      console.error('Error adding or updating announcement:', error);
      toast.error('Failed to add or update announcement');
    }
  };

  const handleEditClick = (announcement) => {
    setSelectedAnnouncement(announcement);
    setTarget(announcement.Target);
    setDate(announcement.Date);
    setDescription(announcement.Description);
    setIsEditMode(true);
  };

  const handleDeleteClick = async (AnnouncementID) => {
    const confirmDelete = window.confirm('Do you want to delete this announcement?');
    if (confirmDelete) {
      try {
        await axios.delete(`${BASE_URL}/announcements/${AnnouncementID}`);
        toast.success('Announcement deleted successfully');
        fetchAnnouncements();
      } catch (error) {
        console.error('Error deleting announcement:', error);
        toast.error('Failed to delete announcement');
      }
    }
  };

  const handleViewClick = (announcement) => {
    setSelectedAnnouncement(announcement);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedAnnouncement(null);
  };

  // Pagination logic
  const indexOfLastAnnouncement = currentPage * announcementsPerPage;
  const indexOfFirstAnnouncement = indexOfLastAnnouncement - announcementsPerPage;
  const currentAnnouncements = announcements.slice(indexOfFirstAnnouncement, indexOfLastAnnouncement);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(announcements.length / announcementsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4 text-center">Announcements</h2>
      <div className="mb-4 max-w-lg mx-auto">
        <label className="block mb-2">Target:</label>
        <select
          value={target}
          onChange={(e) => setTarget(e.target.value)}
          className="p-2 border rounded w-full mb-4"
        >
          <option value="students">Students</option>
          <option value="employees">Employees</option>
        </select>
        <label className="block mb-2">Date:</label>
        <input
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          className="p-2 border rounded w-full mb-4"
        />
        <label className="block mb-2">Description:</label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="p-2 border rounded w-full mb-4"
        />
        <button
          onClick={handleAddOrEdit}
          className="p-2 bg-blue-500 text-white rounded mr-2"
        >
          {isEditMode ? 'Update Announcement' : 'Add Announcement'}
        </button>
        <button
          onClick={() => {
            setTarget('students');
            setDate('');
            setDescription('');
            setSelectedAnnouncement(null);
            setIsEditMode(false);
          }}
          className="p-2 bg-gray-500 text-white rounded"
        >
          Clear
        </button>
      </div>
      <table className="min-w-full bg-white border">
        <thead className="bg-[#BB005A] text-white text-start font-bold">
          <tr>
            <th className="py-2 px-2 border">Target</th>
            <th className="py-2 px-2 border">Date</th>
            <th className="py-2 px-2 border w-2/5">Description</th>
            <th className="py-2 px-2 border">Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentAnnouncements.map((announcement) => (
            <tr key={announcement.AnnouncementID}>
              <td className="py-1 px-2 border">{announcement.Target}</td>
              <td className="py-1 px-2 border">{new Date(announcement.Date).toISOString().split('T')[0]}</td>
              <td className="py-1 px-2 border w-2/5">
                {announcement.Description.length > 50
                  ? `${announcement.Description.substring(0, 50)}...`
                  : announcement.Description}
              </td>
              <td className="py-1 px-2 border flex items-center justify-around">
                <button
                  onClick={() => handleViewClick(announcement)}
                  className="p-1 bg-green-500 text-white rounded mr-2"
                >
                  View
                </button>
                <button
                  onClick={() => handleEditClick(announcement)}
                  className="p-1 bg-yellow-500 text-white rounded mr-2"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDeleteClick(announcement.AnnouncementID)}
                  className="p-1 bg-red-500 text-white rounded"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-center mt-4">
        {pageNumbers.map(number => (
          <button
            key={number}
            onClick={() => setCurrentPage(number)}
            className={`p-2 mx-1 rounded ${currentPage === number ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          >
            {number}
          </button>
        ))}
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded-lg max-w-lg w-full">
            <h3 className="text-xl font-bold mb-2">Announcement Details</h3>
            <div className="overflow-y-auto max-h-180">
              <p><strong>Target:</strong> {selectedAnnouncement.Target}</p>
              <p><strong>Date:</strong> {new Date(selectedAnnouncement.Date).toISOString().split('T')[0]}</p>
              <p><strong>Description:</strong> {selectedAnnouncement.Description}</p>
            </div>
            <button
              onClick={closeModal}
              className="mt-4 p-2 bg-blue-500 text-white rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default Announcements;
