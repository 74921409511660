import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../context/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ViewUsers = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/auth/get-all-users`);
        setUsers(response.data);
      } catch (error) {
        toast.error('Failed to fetch users');
      }
    };
    fetchUsers();
  }, []);

  const handleDeleteUser = async (id, username) => {
    if (['sysadmin', 'financeadmin'].includes(username)) {
      toast.error(`Cannot delete ${username}`);
      return;
    }

    if (window.confirm(`Are you sure you want to delete ${username}?`)) {
      try {
        await axios.delete(`${BASE_URL}/auth/delete-user/${id}`);
        toast.success('User deleted');
        setUsers(users.filter(user => user.id !== id));
      } catch (error) {
        toast.error('Failed to delete user');
      }
    }
  };

  return (
    <div className="p-4">
      <h3 className="text-xl font-semibold mb-4">Users</h3>
      <table className="min-w-full bg-white border">
        <thead>
          <tr className="bg-gray-200">
            <th className="py-2 px-4 text-start border">Username</th>
            <th className="py-2 px-4 text-start border">Role</th>
            <th className="py-2 px-4 text-start border">Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td className="py-2 px-4 border">{user.username}</td>
              <td className="py-2 px-4 border">{user.role}</td>
              <td className="py-2 px-4 border">
                <button onClick={() => handleDeleteUser(user.id, user.username)} className="p-2 bg-red-500 text-white rounded">Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ToastContainer />
    </div>
  );
};

export default ViewUsers;
