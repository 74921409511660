import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from '../../context/api';

const defaultDepartment = 'Teaching';
const defaultRole = 'Teacher';

const EditEmployee = () => {
  const { employeeId } = useParams();
  const navigate = useNavigate();
  const [employee, setEmployee] = useState({
    Department: defaultDepartment,
    Role: defaultRole,
    Name: '',
    Surname: '',
    DateOfBirth: '',
    Address: '',
    PhoneNumber: '',
    NationalIDNumber: '',
    Gender: '',
    EmployeeNumber: '',
    Password: ''
  });

  useEffect(() => {
    const fetchEmployee = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/teachers/get-teacher/${employeeId}`);
        const data = response.data;
        data.DateOfBirth = new Date(data.DateOfBirth).toISOString().split('T')[0];
        setEmployee(data);
      } catch (error) {
        console.error('Error fetching employee:', error);
      }
    };
    fetchEmployee();
  }, [employeeId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmployee((prevEmployee) => ({
      ...prevEmployee,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`${BASE_URL}/teachers/edit-teacher/${employeeId}`, employee);
      if (response.status === 200) {
        toast.success('Employee updated successfully');
        setTimeout(() => {
          navigate('/employees');
        }, 2000);
      }
    } catch (error) {
      toast.error('Error updating employee');
    }
  };

  return (
    <div className="p-6 bg-white rounded shadow-md max-w-lg mx-auto">
      <h2 className="text-3xl font-bold text-[#11024D] mb-6">Edit Teacher</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-[#11024D] font-medium mb-2">Name</label>
          <input
            type="text"
            name="Name"
            value={employee.Name}
            onChange={handleChange}
            className="p-2 border rounded w-full"
            required
          />
        </div>
        <div>
          <label className="block text-[#11024D] font-medium mb-2">Surname</label>
          <input
            type="text"
            name="Surname"
            value={employee.Surname}
            onChange={handleChange}
            className="p-2 border rounded w-full"
            required
          />
        </div>
        <div>
          <label className="block text-[#11024D] font-medium mb-2">Date of Birth</label>
          <input
            type="date"
            name="DateOfBirth"
            value={employee.DateOfBirth}
            onChange={handleChange}
            className="p-2 border rounded w-full"
            required
          />
        </div>
        <div>
          <label className="block text-[#11024D] font-medium mb-2">Address</label>
          <input
            type="text"
            name="Address"
            value={employee.Address}
            onChange={handleChange}
            className="p-2 border rounded w-full"
            required
          />
        </div>
        <div>
          <label className="block text-[#11024D] font-medium mb-2">Phone Number</label>
          <input
            type="text"
            name="PhoneNumber"
            value={employee.PhoneNumber}
            onChange={handleChange}
            className="p-2 border rounded w-full"
            required
          />
        </div>
        <div>
          <label className="block text-[#11024D] font-medium mb-2">National ID Number</label>
          <input
            type="text"
            name="NationalIDNumber"
            value={employee.NationalIDNumber}
            onChange={handleChange}
            className="p-2 border rounded w-full"
            required
          />
        </div>
        <div>
          <label className="block text-[#11024D] font-medium mb-2">Gender</label>
          <select
            name="Gender"
            value={employee.Gender}
            onChange={handleChange}
            className="p-2 border rounded w-full"
            required
          >
            <option value="" disabled>Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>
        <div>
          <label className="block text-[#11024D] font-medium mb-2">Employee Number</label>
          <input
            type="text"
            name="EmployeeNumber"
            value={employee.EmployeeNumber}
            onChange={handleChange}
            className="p-2 border rounded w-full"
            required
            readOnly
          />
        </div>
        <div>
          <label className="block text-[#11024D] font-medium mb-2">Password</label>
          <input
            type="text"
            name="Password"
            value={employee.Password}
            onChange={handleChange}
            className="p-2 border rounded w-full"
            required
          />
        </div>
        <button
          type="submit"
          className="p-2 bg-[#11024D] text-white rounded hover:bg-yellow-500"
        >
          Update Teacher
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default EditEmployee;
