import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../context/api';

const SchemeBooks = () => {
  const [schemeBooks, setSchemeBooks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage] = useState(100);

  useEffect(() => {
    fetchSchemeBooks();
  }, [page]);

  const fetchSchemeBooks = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/scheme-books`, {
        params: { page, perPage }
      });
      setSchemeBooks(response.data.schemeBooks);
      setTotalPages(response.data.totalPages);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching scheme books:', err);
      setError('Failed to fetch scheme books');
      setLoading(false);
    }
  };

  const searchSchemeBooks = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/search-scheme-books`, {
        params: { search: searchQuery }
      });
      if (response.data.schemeBooks.length === 0) {
        setError('Scheme book not found');
      } else {
        setSchemeBooks(response.data.schemeBooks);
        setTotalPages(1);
        setError('');
      }
      setLoading(false);
    } catch (err) {
      console.error('Error searching scheme books:', err);
      setError('Failed to search scheme books');
      setLoading(false);
    }
  };

  const handleDownload = async (fileName) => {
    try {
      const response = await axios.get(`${BASE_URL}/scheme_books/${fileName}`, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      console.error('Error downloading file:', err);
      alert('Failed to download file');
    }
  };

  const handleSearch = () => {
    if (searchQuery.trim() !== '') {
      searchSchemeBooks();
    } else {
      setError('Please enter a search term.');
    }
  };

  const handleResetSearch = () => {
    setSearchQuery('');
    setError('');
    fetchSchemeBooks();
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  if (loading) {
    return <div className="text-center mt-4">Loading...</div>;
  }

  return (
    <div className="p-6">
      <h2 className="text-3xl font-bold mb-6 text-center text-white bg-[#11024D] py-2 rounded">
        Scheme Books
      </h2>

      {/* Search Bar */}
      <div className="mb-6">
        <input
          type="text"
          placeholder="Search by EmployeeNumber, Description, or Class"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="border p-2 rounded w-full focus:outline-none focus:ring-2 focus:ring-[#11024D]"
        />
        <div className="mt-4 flex gap-4">
          <button
            onClick={handleSearch}
            className="bg-[#11024D] text-white px-4 py-2 rounded hover:bg-[#11024D] transition-all"
          >
            Search
          </button>
          <button
            onClick={handleResetSearch}
            className="bg-gray-400 text-white px-4 py-2 rounded hover:bg-gray-500 transition-all"
          >
            Reset Search
          </button>
        </div>
      </div>

      {error && <div className="text-red-500 mb-4">{error}</div>}

      {/* Scheme Books Table */}
      {schemeBooks.length > 0 ? (
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr className="bg-[#11024D] text-white">
              <th className="py-2 px-4 text-start border-b">Class</th>
              <th className="py-2 px-4 text-start border-b">Description</th>
              <th className="py-2 px-4 text-start border-b">Teacher</th>
              <th className="py-2 px-4 text-start border-b">Date</th>
              <th className="py-2 px-4 text-start border-b">Download</th>
            </tr>
          </thead>
          <tbody>
            {schemeBooks.map((schemeBook) => (
              <tr key={schemeBook.id}>
                <td className="py-2 px-4 border-b">{schemeBook.class}</td>
                <td className="py-2 px-4 border-b">{schemeBook.description}</td>
                <td className="py-2 px-4 border-b">{schemeBook.Name} {schemeBook.Surname}</td>
                <td className="py-2 px-4 border-b">{new Date(schemeBook.date).toLocaleDateString()}</td>
                <td className="py-2 px-4 border-b">
                  <button
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-all"
                    onClick={() => handleDownload(schemeBook.document)}
                  >
                    Download
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        !error && <div className="text-gray-600 text-center mt-4">No scheme books found.</div>
      )}

      {/* Pagination Controls */}
      {schemeBooks.length > 0 && (
        <div className="flex justify-between mt-6">
          <button
            onClick={() => handlePageChange(page - 1)}
            disabled={page === 1}
            className="px-4 py-2 bg-gray-300 text-black rounded disabled:opacity-50 hover:bg-gray-400 transition-all"
          >
            Previous
          </button>
          <span className="text-gray-700">
            Page {page} of {totalPages}
          </span>
          <button
            onClick={() => handlePageChange(page + 1)}
            disabled={page >= totalPages}
            className="px-4 py-2 bg-gray-300 text-black rounded disabled:opacity-50 hover:bg-gray-400 transition-all"
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default SchemeBooks;
