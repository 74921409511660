import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../context/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'tailwindcss/tailwind.css';

const ViewEmployee = () => {
  const { EmployeeID } = useParams();
  const navigate = useNavigate();
  const [employee, setEmployee] = useState(null);
  const [isDeleteEmployeeModalOpen, setIsDeleteEmployeeModalOpen] = useState(false);

  useEffect(() => {
    const fetchEmployee = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/teachers/get-teacher/${EmployeeID}`);
        setEmployee(response.data);
      } catch (error) {
        console.error('Error fetching employee details:', error);
      }
    };

    fetchEmployee();
  }, [EmployeeID]);

  const openDeleteEmployeeModal = () => {
    setIsDeleteEmployeeModalOpen(true);
  };

  const closeDeleteEmployeeModal = () => {
    setIsDeleteEmployeeModalOpen(false);
  };

  const handleDeleteEmployee = async () => {
    try {
      await axios.put(`${BASE_URL}/teachers/delete-teacher/${EmployeeID}`);
      toast.success('Employee deleted successfully');
      navigate('/employees');
    } catch (error) {
      console.error('Error deleting employee:', error);
      toast.error('Failed to delete employee');
    }
  };

  return (
    <div className="p-4">
      {employee && (
        <>
          <div className="flex justify-between items-center mb-4">
            <div>
              <h3 className="text-xl font-bold text-[#11024D]">{employee.Name} {employee.Surname}</h3>
              <p className="text-gray-600">{employee.Department} - {employee.Role}</p>
            </div>
            <div className="flex">
              <Link to={`/edit-employee/${employee.EmployeeID}`} className="p-2 bg-blue-500 text-white rounded mr-2">Edit</Link>
              <button
                onClick={openDeleteEmployeeModal}
                className="p-2 bg-red-500 text-white rounded"
              >
                Delete
              </button>
            </div>
          </div>

          <div className="mb-4">
            <button
              className={`p-2 border-2 border-[#11024D] text-[#11024D] rounded mr-2`}
            >
              Personal Info
            </button>
          </div>

          <div className="bg-white p-4 rounded shadow-md">
            <h3 className="text-xl font-bold mb-4 border-b-2 border-[#11024D]">Personal Information</h3>
            <div className="grid grid-cols-2 gap-4">
              <p><strong>Name:</strong> {employee.Name}</p>
              <p><strong>Surname:</strong> {employee.Surname}</p>
              <p><strong>Department:</strong> {employee.Department}</p>
              <p><strong>Role:</strong> {employee.Role}</p>
              <p><strong>Date of Birth:</strong> {new Date(employee.DateOfBirth).toISOString().split('T')[0]}</p>
              <p><strong>Address:</strong> {employee.Address}</p>
              <p><strong>Phone Number:</strong> {employee.PhoneNumber}</p>
              <p><strong>National ID Number:</strong> {employee.NationalIDNumber}</p>
              <p><strong>Gender:</strong> {employee.Gender}</p>
              <p><strong>Employee Number:</strong> {employee.EmployeeNumber}</p>
            </div>
          </div>

          {isDeleteEmployeeModalOpen && (
            <div className="fixed inset-0 z-50 flex items-center justify-center">
              <div className="fixed inset-0 bg-black opacity-50" onClick={closeDeleteEmployeeModal}></div>
              <div className="bg-white p-6 rounded-lg shadow-lg z-10 w-full max-w-md">
                <h3 className="text-2xl font-bold mb-4">Confirm Deletion</h3>
                <p>Hey, you are about to delete this teacher. Are you sure?</p>
                <div className="flex justify-end mt-4">
                  <button
                    onClick={closeDeleteEmployeeModal}
                    className="p-2 bg-gray-500 text-white rounded mr-2"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleDeleteEmployee}
                    className="p-2 bg-red-500 text-white rounded"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      <ToastContainer />
    </div>
  );
};

export default ViewEmployee;
