import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../context/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EditGradeLevelClass = () => {
  const { classId } = useParams();
  const navigate = useNavigate();
  const [classData, setClassData] = useState({
    ClassName: '',
    Form: '',
    Teacher: '',
    TeacherName: '',
    Term: '',
    Year: ''
  });
  const [teachers, setTeachers] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    fetchClassData();
    fetchTeachers();
  }, [classId]);

  const fetchClassData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/gradelevelclasses/get-gradelevelclass/${classId}`);
      setClassData(response.data);
    } catch (error) {
      console.error('Error fetching class data:', error);
    }
  };

  const fetchTeachers = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/teachers/get-all-teachers`);
      // Access the 'employees' key from the response data
      setTeachers(response.data.employees || []);
    } catch (error) {
      console.error('Error fetching teachers:', error);
    }
  };

  useEffect(() => {
    if (classData.Teacher && teachers.length > 0) {
      const selectedTeacher = teachers.find(
        (teacher) => teacher.EmployeeNumber === classData.Teacher
      );
      if (selectedTeacher) {
        setClassData((prevData) => ({
          ...prevData,
          Teacher: selectedTeacher.EmployeeNumber,
          TeacherName: `${selectedTeacher.Name} ${selectedTeacher.Surname}`,
        }));
      }
    }
  }, [classData.Teacher, teachers]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClassData({ ...classData, [name]: value });
  };

  const handleTeacherChange = (e) => {
    const selectedTeacher = teachers.find((teacher) => teacher.EmployeeNumber === e.target.value);
    setClassData({
      ...classData,
      Teacher: selectedTeacher ? selectedTeacher.EmployeeNumber : '',
      TeacherName: selectedTeacher ? `${selectedTeacher.Name} ${selectedTeacher.Surname}` : '',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${BASE_URL}/gradelevelclasses/update-gradelevelclass/${classId}`, classData);
      setShowSuccessModal(true);
    } catch (error) {
      console.error('Error updating class:', error);
      toast.error('Failed to update class.');
    }
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false);
    navigate(`/view-class/${classId}`);
  };

  return (
    <div className="container mx-auto p-4">
      <ToastContainer />
      <h2 className="text-2xl font-semibold mb-4">Edit Class</h2>
      <form className="bg-white p-4 rounded shadow-md" onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2" htmlFor="ClassName">
            Class Name
          </label>
          <input
            type="text"
            id="ClassName"
            name="ClassName"
            value={classData.ClassName}
            onChange={handleInputChange}
            className="border rounded px-4 py-2 w-full"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2" htmlFor="Form">
            Form
          </label>
          <select
            id="Form"
            name="Form"
            value={classData.Form}
            onChange={handleInputChange}
            className="border rounded px-4 py-2 w-full"
            required
          >
            <option value="">Select Form</option>
            {[1, 2, 3, 4, 5, 6].map((form) => (
              <option key={form} value={form}>
                {form}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2" htmlFor="Teacher">
            Teacher
          </label>
          <select
            id="Teacher"
            name="Teacher"
            value={classData.Teacher}
            onChange={handleTeacherChange}
            className="border rounded px-4 py-2 w-full"
            required
          >
            {teachers.length === 0 && <option value="">Loading teachers...</option>}
            {teachers.length > 0 && (
              <option value={classData.Teacher}>
                {classData.TeacherName || 'Select Teacher'}
              </option>
            )}
            {teachers.map((teacher) => (
              <option key={teacher.EmployeeNumber} value={teacher.EmployeeNumber}>
                {`${teacher.Name} ${teacher.Surname}`}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2" htmlFor="Term">
            Term
          </label>
          <select
            id="Term"
            name="Term"
            value={classData.Term}
            onChange={handleInputChange}
            className="border rounded px-4 py-2 w-full"
            required
          >
            <option value="">Select Term</option>
            {[1, 2, 3].map((term) => (
              <option key={term} value={term}>
                {term}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2" htmlFor="Year">
            Year
          </label>
          <select
            id="Year"
            name="Year"
            value={classData.Year}
            onChange={handleInputChange}
            className="border rounded px-4 py-2 w-full"
            required
          >
            <option value="">Select Year</option>
            {Array.from({ length: 78 }, (_, i) => 2023 + i).map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
          Update Class
        </button>
      </form>

      {/* Success Modal */}
      {showSuccessModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded shadow-md">
            <h3 className="text-xl font-semibold mb-4">Class Updated Successfully</h3>
            <div className="flex justify-end">
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                onClick={handleCloseModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditGradeLevelClass;
