import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BASE_URL } from '../../context/api';
import { FaPlus, FaTimes } from 'react-icons/fa';

const CreateInvoice = () => {
  const [invoice, setInvoice] = useState({
    form: '',
    term: '',
    year: '',
    items: [{ name: '', amount: '' }],
  });

  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInvoice({ ...invoice, [name]: value });
  };

  const handleFormChange = (e) => {
    const { value } = e.target;
    setInvoice({ ...invoice, form: value });
  };

  const handleItemChange = (index, e) => {
    const { name, value } = e.target;
    const items = [...invoice.items];
    items[index][name] = name === 'amount' ? parseFloat(value) : value;
    setInvoice({ ...invoice, items });
  };

  const addItem = () => {
    setInvoice({ ...invoice, items: [...invoice.items, { name: '', amount: '' }] });
  };

  const removeItem = (index) => {
    const items = [...invoice.items];
    items.splice(index, 1);
    setInvoice({ ...invoice, items });
  };

  const validateForm = () => {
    const { form, term, year, items } = invoice;

    if (!form || !term || !year || items.length === 0) {
      setErrorMessage('Please fill out all fields.');
      return false;
    }

    if (isNaN(year) || parseInt(year) <= 0) {
      setErrorMessage('Year must be a positive number.');
      return false;
    }

    for (let item of items) {
      if (!item.name || isNaN(item.amount) || item.amount <= 0) {
        setErrorMessage('Each item must have a valid name and a positive amount.');
        return false;
      }
    }

    setErrorMessage('');
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const formattedItems = invoice.items.map(item => ({
      ...item,
      amount: parseFloat(item.amount)
    }));

    try {
      const response = await axios.post(`${BASE_URL}/invoices/create-invoice`, { ...invoice, items: formattedItems });
      if (response.status === 201) {
        setShowModal(true);
        setInvoice({
          form: '',
          term: '',
          year: '',
          items: [{ name: '', amount: '' }],
        });
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        toast.error('Failed to create invoice.');
      }
    }
  };

  return (
    <div className="w-full p-6 bg-white">
      <h2 className="text-3xl font-bold text-[#11024D] mb-6">Create Invoice</h2>
      {errorMessage && <div className="bg-red-100 text-red-700 p-3 rounded mb-4">{errorMessage}</div>}
      <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-6">
        <div className="mb-4">
          <label className="block text-[#11024D] font-medium mb-2">Form</label>
          <select
            name="form"
            value={invoice.form}
            onChange={handleFormChange}
            className="mt-1 p-3 border border-[#11024D] rounded w-full focus:outline-none focus:ring-2 focus:ring-yellow-500"
          >
            <option value="">Select Form</option>
            {[1, 2, 3, 4, 5, 6].map(form => (
              <option key={form} value={form}>{form}</option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-[#11024D] font-medium mb-2">Term</label>
          <select
            name="term"
            value={invoice.term}
            onChange={handleInputChange}
            className="mt-1 p-3 border border-[#11024D] rounded w-full focus:outline-none focus:ring-2 focus:ring-yellow-500"
          >
            <option value="">Select Term</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-[#11024D] font-medium mb-2">Year</label>
          <input
            type="number"
            name="year"
            value={invoice.year}
            onChange={handleInputChange}
            className="mt-1 p-3 border border-[#11024D] rounded w-full focus:outline-none focus:ring-2 focus:ring-yellow-500"
          />
        </div>
        <div className="col-span-2 mb-6">
          <label className="block text-[#11024D] font-medium mb-2">Invoice Items</label>
          {invoice.items.map((item, index) => (
            <div key={index} className="flex items-center mb-2">
              <input
                type="text"
                name="name"
                placeholder="Item Name"
                value={item.name}
                onChange={(e) => handleItemChange(index, e)}
                className="mr-2 p-3 border border-[#11024D] rounded w-1/2 focus:outline-none focus:ring-2 focus:ring-yellow-500"
              />
              <input
                type="number"
                name="amount"
                placeholder="Amount"
                value={item.amount}
                onChange={(e) => handleItemChange(index, e)}
                className="mr-2 p-3 border border-[#11024D] rounded w-1/3 focus:outline-none focus:ring-2 focus:ring-yellow-500"
              />
              <button
                type="button"
                onClick={() => removeItem(index)}
                className="text-red-500 flex items-center hover:text-red-700"
              >
                <FaTimes className="mr-1" /> Remove
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={addItem}
            className="text-[#11024D] flex items-center hover:text-yellow-500"
          >
            <FaPlus className="mr-1" /> Add Item
          </button>
        </div>
        <div className="col-span-2">
          <button
            type="submit"
            className="bg-[#11024D] text-white w-full p-3 rounded hover:bg-yellow-500"
          >
            Create Invoice
          </button>
        </div>
      </form>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-green-100 p-6 rounded max-w-md w-full">
            <h3 className="text-lg font-bold text-green-700">Invoice Created Successfully!</h3>
            <button
              onClick={() => setShowModal(false)}
              className="mt-4 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
            >
              Close
            </button>
          </div>
        </div>
      )}

    </div>
  ); 
};

export default CreateInvoice;
