import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import Login from './pages/Auth/Login';
import Sidebar from './components/Sidebar';

//students
import Homepage from './pages/students/Homepage';
import ViewStudent from './pages/students/ViewStudent';
import CreateStudent from './pages/students/CreateStudent';

//financials
import CreateInvoice from './pages/financials/CreateInvoice'
import EditInvoice from './pages/financials/EditInvoice'
import EditPayment from './pages/financials/EditPayment'
import MakePayment from './pages/financials/MakePayment'
import Payments from './pages/financials/Payments'
import StudentRecords from './pages/financials/StudentRecords'
import ViewInvoices from './pages/financials/ViewInvoices'

//results

import Results from './pages/results/Results'
import ViewClassResults from './pages/results/ViewClassResults'


//teachers
import AddEmployee from './pages/teachers/AddEmployee'
import EditEmployee from './pages/teachers/EditEmployee'
import EmployeesPage from './pages/teachers/EmployeesPage'

//settings
import AddUser from './pages/settings/AddUser';
import ChangePassword from './pages/settings/ChangePassword';
import ViewUsers from './pages/settings/ViewUsers';

//timetables 
import AddTimetablePage from './pages/timetables/AddTimetablePage'
import EditTimetablePage from './pages/timetables/EditTimetablePage'
import ViewTimetablePage from './pages/timetables/ViewTimetablePage'


//announcements
import Announcements from './pages/announcements/Announcements';

//classes
import Classes from './pages/classes/Classes';
import ViewSubjectClass from './pages/classes/ViewSubjectClass';
import EditSubjectLevelClass from './pages/classes/EditSubjectLevelClass';
import EditGradeLevelClass from './pages/classes/EditGradelevelclass';
import AddGradeLevelClass from './pages/classes/AddGradeLevelClass';
import AddSubjectLevelClass from './pages/classes/AddSubjectLevelClass';
import ViewEmployee from './pages/teachers/ViewEmployee';
import ViewClass from './pages/classes/Viewclass';
import SchemeBooks from './pages/teachers/SchemeBooks';

const App = () => {
  const location = useLocation();
  const showSidebar = location.pathname !== '/';

  return (
    <AuthProvider>
      <div className="flex">
        {showSidebar && <Sidebar />}
        <div className={showSidebar ? "ml-64 flex-1" : "flex-1"}>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route
              path="/students"
              element={
                <PrivateRoute>
                  <Homepage />
                </PrivateRoute>
              }
            />
            <Route
              path="/view-student/:regnumber"
              element={
                <PrivateRoute>
                  <ViewStudent />
                </PrivateRoute>
              }
            />
            <Route
              path="/create-student"
              element={
                <PrivateRoute>
                  <CreateStudent />
                </PrivateRoute>
              }
            />
            <Route //financials
              path="/create-invoice"
              element={
                <PrivateRoute>
                  <CreateInvoice />
                </PrivateRoute>
              }
            />
            <Route
              path="/edit-invoice/:id"
              element={
                <PrivateRoute>
                  <EditInvoice />
                </PrivateRoute>
              }
            />
            <Route
              path="/edit-payment/:id"
              element={
                <PrivateRoute>
                  <EditPayment />
                </PrivateRoute>
              }
            />
            <Route
              path="/make-payment"
              element={
                <PrivateRoute>
                  <MakePayment />
                </PrivateRoute>
              }
            />
            <Route
              path="/payments"
              element={
                <PrivateRoute>
                  <Payments />
                </PrivateRoute>
              }
            />
            <Route
              path="/student-records"
              element={
                <PrivateRoute>
                  <StudentRecords />
                </PrivateRoute>
              }
            />
            <Route
              path="/view-invoices"
              element={
                <PrivateRoute>
                  <ViewInvoices />
                </PrivateRoute>
              }
            />

            {/* Timetable Routes */}
            <Route
              path="/add-timetable"
              element={
                <PrivateRoute>
                  <AddTimetablePage />
                </PrivateRoute>
              }
            />
            <Route
              path="/view-timetable"
              element={
                <PrivateRoute>
                  <ViewTimetablePage />
                </PrivateRoute>
              }
            />
            <Route
              path="/edit-timetable"
              element={
                <PrivateRoute>
                  <EditTimetablePage />
                </PrivateRoute>
              }
            />
            <Route
              path="/create-employee"
              element={
                <PrivateRoute>
                  <AddEmployee />
                </PrivateRoute>
              }
            />
            <Route
              path="/edit-employee/:employeeId"
              element={
                <PrivateRoute>
                  <EditEmployee />
                </PrivateRoute>
              }
            />
            <Route
              path="/employees"
              element={
                <PrivateRoute>
                  <EmployeesPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/view-employee/:EmployeeID"
              element={
                <PrivateRoute>
                  <ViewEmployee />
                </PrivateRoute>
              }
            />
            <Route
              path="/results"
              element={
                <PrivateRoute>
                  <Results />
                </PrivateRoute>
              }
            />
            <Route
              path="/view-classresults/:classID/:form"
              element={
                <PrivateRoute>
                  <ViewClassResults />
                </PrivateRoute>
              }
            />
            <Route
              path="/announcements"
              element={
                <PrivateRoute>
                  <Announcements />
                </PrivateRoute>
              }
            />
            <Route
              path="/add-user"
              element={
                <PrivateRoute>
                  <AddUser />
                </PrivateRoute>
              }
            />
            <Route
              path="/change-user-password"
              element={
                <PrivateRoute>
                  <ChangePassword />
                </PrivateRoute>
              }
            />
            <Route
              path="/users"
              element={
                <PrivateRoute>
                  <ViewUsers />
                </PrivateRoute>
              }
            />
            <Route
              path="/classes"
              element={
                <PrivateRoute>
                  <Classes />
                </PrivateRoute>
              }
            />

            <Route
              path="/edit-class"
              element={
                <PrivateRoute>
                  <EditSubjectLevelClass />
                </PrivateRoute>
              }
            />

            <Route
              path="/view-classresults/:classID/:form"
              element={
                <PrivateRoute>
                  <ViewClassResults />
                </PrivateRoute>
              }
            />
            <Route
              path="/view-subjectclass/:classId"
              element={
                <PrivateRoute>
                  <ViewSubjectClass />
                </PrivateRoute>
              }
            />
            <Route
              path="/edit-subjectclass/:classId"
              element={
                <PrivateRoute>
                  <EditSubjectLevelClass />
                </PrivateRoute>
              }
            />
            <Route
              path="/edit-gradelevelclass/:classId"
              element={
                <PrivateRoute>
                  <EditGradeLevelClass />
                </PrivateRoute>
              }
            />
            <Route
              path="/add-gradelevelclass"
              element={
                <PrivateRoute>
                  <AddGradeLevelClass />
                </PrivateRoute>
              }
            />
            <Route
              path="/add-subjectlevelclass"
              element={
                <PrivateRoute>
                  <AddSubjectLevelClass />
                </PrivateRoute>
              }
            />
             <Route
              path="/scheme-books"
              element={
                <PrivateRoute>
                  <SchemeBooks />
                </PrivateRoute>
              }
            />
              <Route
              path="/view-class/:classId"
              element={
                <PrivateRoute>
                  <ViewClass />
                </PrivateRoute>
              }
            />
          </Routes>
        </div>
      </div>
    </AuthProvider>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
