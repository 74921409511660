import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from '../../context/api';

// Default values for role and department
const defaultDepartment = 'Teaching';
const defaultRole = 'Teacher';

const generateEmployeeNumber = () => {
  const randomNumber = Math.floor(1000 + Math.random() * 9000);
  const randomLetter = String.fromCharCode(65 + Math.floor(Math.random() * 26));
  return `V${randomNumber}${randomLetter}`;
};

const AddEmployee = () => {
  const [employee, setEmployee] = useState({
    Department: defaultDepartment, // Default to Teaching
    Role: defaultRole, // Default to Teacher
    Name: '',
    Surname: '',
    DateOfBirth: '',
    Address: '',
    PhoneNumber: '',
    NationalIDNumber: '',
    Gender: '',
    EmployeeNumber: '',
    Password: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmployee((prevEmployee) => ({
      ...prevEmployee,
      [name]: value,
    }));
  };

  const handleGenerateEmployeeNumber = () => {
    const newEmployeeNumber = generateEmployeeNumber();
    setEmployee((prevEmployee) => ({
      ...prevEmployee,
      EmployeeNumber: newEmployeeNumber,
      Password: newEmployeeNumber,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${BASE_URL}/teachers/add-teacher`, employee);
      if (response.status === 200) {
        toast.success('Employee created successfully');
        setEmployee({
          Department: defaultDepartment, // Default back to Teaching
          Role: defaultRole, // Default back to Teacher
          Name: '',
          Surname: '',
          DateOfBirth: '',
          Address: '',
          PhoneNumber: '',
          NationalIDNumber: '',
          Gender: '',
          EmployeeNumber: '',
          Password: ''
        });
      }
    } catch (error) {
      toast.error('Error creating employee');
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Add Teachers</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block">Name</label>
          <input
            type="text"
            name="Name"
            value={employee.Name}
            onChange={handleChange}
            className="p-2 border rounded w-full"
            required
          />
        </div>
        <div>
          <label className="block">Surname</label>
          <input
            type="text"
            name="Surname"
            value={employee.Surname}
            onChange={handleChange}
            className="p-2 border rounded w-full"
            required
          />
        </div>
        <div>
          <label className="block">Date of Birth</label>
          <input
            type="date"
            name="DateOfBirth"
            value={employee.DateOfBirth}
            onChange={handleChange}
            className="p-2 border rounded w-full"
            required
          />
        </div>
        <div>
          <label className="block">Address</label>
          <input
            type="text"
            name="Address"
            value={employee.Address}
            onChange={handleChange}
            className="p-2 border rounded w-full"
            required
          />
        </div>
        <div>
          <label className="block">Phone Number</label>
          <input
            type="text"
            name="PhoneNumber"
            value={employee.PhoneNumber}
            onChange={handleChange}
            className="p-2 border rounded w-full"
            required
          />
        </div>
        <div>
          <label className="block">National ID Number</label>
          <input
            type="text"
            name="NationalIDNumber"
            value={employee.NationalIDNumber}
            onChange={handleChange}
            className="p-2 border rounded w-full"
            required
          />
        </div>
        <div>
          <label className="block">Gender</label>
          <select
            name="Gender"
            value={employee.Gender}
            onChange={handleChange}
            className="p-2 border rounded w-full"
            required
          >
            <option value="" disabled>Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>
        <div>
          <label className="block">Employee Number</label>
          <div className="flex items-center">
            <input
              type="text"
              name="EmployeeNumber"
              value={employee.EmployeeNumber}
              onChange={handleChange}
              className="p-2 border rounded w-full mr-2"
              required
              readOnly
            />
            <button
              type="button"
              onClick={handleGenerateEmployeeNumber}
              className="p-2 bg-blue-500 text-white rounded"
            >
              Generate
            </button>
          </div>
        </div>
        <div>
          <label className="block">Password</label>
          <input
            type="text"
            name="Password"
            value={employee.Password}
            onChange={handleChange}
            className="p-2 border rounded w-full"
            required
            readOnly
          />
        </div>

        <button
          type="submit"
          className="p-2 bg-green-500 text-white rounded"
        >
          Add Teacher
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default AddEmployee;
