import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../context/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ViewClass = () => {
  const { classId } = useParams();
  const navigate = useNavigate();
  const [classData, setClassData] = useState(null);
  const [subjectClasses, setSubjectClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [selectedTab, setSelectedTab] = useState('info');
  const [showModal, setShowModal] = useState(false);
  const [newStudentRegNumber, setNewStudentRegNumber] = useState('');
  const [genderFilter, setGenderFilter] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [studentToDelete, setStudentToDelete] = useState(null);

  useEffect(() => {
    fetchClassData();
    fetchSubjectClasses();
    fetchStudents();
  }, [classId]);

  useEffect(() => {
    setFilteredStudents(
      genderFilter ? students.filter(student => student.Gender === genderFilter) : students
    );
  }, [genderFilter, students]);

  const fetchClassData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/gradelevelclasses/get-gradelevelclass/${classId}`);
      setClassData(response.data);
    } catch (error) {
      console.error('Error fetching class data:', error);
    }
  };

  const fetchSubjectClasses = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/gradelevelclasses/get-subjects-gradelevelclass/${classId}`);
      setSubjectClasses(response.data);
    } catch (error) {
      console.error('Error fetching subject classes:', error);
    }
  };

  const fetchStudents = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/gradelevelclasses-students/get-all-students/${classId}`);
      setStudents(Array.isArray(response.data.students) ? response.data.students : []);
    } catch (error) {
      console.error('Error fetching students:', error);
    }
  };

  const handleAddStudent = async () => {
    try {
      await axios.post(`${BASE_URL}/gradelevelclasses-students/add-student`, { regNumber: newStudentRegNumber, classId });
      fetchStudents();
      setShowModal(false);
      setNewStudentRegNumber('');
      toast.success('Student successfully added to class.');
    } catch (error) {
      console.error('Error adding student to class:', error);
      toast.error('Failed to add student to class. The student might already be in the class.');
    }
  };

  const handleEdit = () => {
    navigate(`/edit-gradelevelclass/${classId}`);
  };

  const handleDeleteStudent = async () => {
    try {
      await axios.delete(`${BASE_URL}/gradelevelclasses-students/remove-student`, { data: { regNumber: studentToDelete.RegNumber, classId } });
      fetchStudents();
      setShowDeleteModal(false);
      toast.success('Student successfully removed from class.');
    } catch (error) {
      console.error('Error removing student from class:', error);
      toast.error('Failed to remove student from class.');
    }
  };

  if (!classData) {
    return <div className="flex justify-center items-center h-screen"><div className="text-xl text-[#11024D]">Loading...</div></div>;
  }

  const renderInfo = () => (
    <div className="p-4 bg-white rounded shadow-md">
      <h3 className="text-2xl font-bold text-[#11024D] border-b pb-2 mb-4">Class Info</h3>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div>
          <p><span className="font-semibold text-[#11024D]">Name:</span> {classData.ClassName}</p>
          <p><span className="font-semibold text-[#11024D]">Teacher:</span> {classData.TeacherName}</p>
          <p><span className="font-semibold text-[#11024D]">Students:</span> {students.length}</p>
        </div>
        <div>
          <p><span className="font-semibold text-[#11024D]">Form:</span> {classData.Form}</p>
        </div>
      </div>
    </div>
  );

  const renderSubjectClasses = () => (
    <div className="p-4 bg-white rounded shadow-md">
      <h3 className="text-2xl font-bold text-[#11024D] border-b pb-2 mb-4">Subject Classes</h3>
      <table className="min-w-full bg-white">
        <thead>
          <tr className="bg-[#11024D] text-white">
            <th className="py-2 px-4 text-left">Class Name</th>
            <th className="py-2 px-4 text-left">Subject</th>
            <th className="py-2 px-4 text-left">Teacher</th>
            <th className="py-2 px-4 text-left">Form</th>
            <th className="py-2 px-4 text-left">Form Level Class</th>
            <th className="py-2 px-4 text-left">Teacher Name</th>
            <th className="py-2 px-4 text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          {subjectClasses.map((subjectClass, index) => (
            <tr key={index} className="border-b">
              <td className="py-2 px-4">{subjectClass.ClassName}</td>
              <td className="py-2 px-4">{subjectClass.Subject}</td>
              <td className="py-2 px-4">{subjectClass.Teacher}</td>
              <td className="py-2 px-4">{subjectClass.Form}</td>
              <td className="py-2 px-4">{classData.ClassName}</td>
              <td className="py-2 px-4">{subjectClass.TeacherName}</td>
              <td className="py-2 px-4">
                <button
                  className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600"
                  onClick={() => navigate(`/view-subjectclass/${subjectClass.ClassID}`)}
                >
                  View
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const renderStudents = () => (
    <div className="p-4 bg-white rounded shadow-md">
      <h3 className="text-2xl font-bold text-[#11024D] border-b pb-2 mb-4">Students (Total: {students.length})</h3>
      <div className="mb-4 flex items-center">
        <label className="mr-4 font-medium text-[#11024D]">Filter by Gender:</label>
        <select
          value={genderFilter}
          onChange={(e) => setGenderFilter(e.target.value)}
          className="border rounded px-4 py-2"
        >
          <option value="">All</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
        </select>
      </div>
      <table className="min-w-full bg-white">
        <thead>
          <tr className="bg-[#11024D] text-white">
            <th className="py-2 px-4 text-left">Name</th>
            <th className="py-2 px-4 text-left">Surname</th>
            <th className="py-2 px-4 text-left">Reg Number</th>
            <th className="py-2 px-4 text-left">Gender</th>
            <th className="py-2 px-4 text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredStudents.map((student, index) => (
            <tr key={index} className="border-b">
              <td className="py-2 px-4">{student.Name}</td>
              <td className="py-2 px-4">{student.Surname}</td>
              <td className="py-2 px-4">{student.RegNumber}</td>
              <td className="py-2 px-4">{student.Gender}</td>
              <td className="py-2 px-4">
                <button
                  className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                  onClick={() => {
                    setStudentToDelete(student);
                    setShowDeleteModal(true);
                  }}
                >
                  Remove from Class
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button
        className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600 mt-4"
        onClick={() => setShowModal(true)}
      >
        Add Student
      </button>
    </div>
  );

  return (
    <div className="container mx-auto p-6">
      <ToastContainer />
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-3xl font-bold text-[#11024D]">{classData.ClassName}</h1>
        <div>
          <button
            className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600"
            onClick={handleEdit}
          >
            Edit Class
          </button>
        </div>
      </div>
      <div className="bg-white rounded shadow-md p-4">
        <div className="flex gap-4 mb-4">
          <button
            className={`px-4 py-2 rounded ${selectedTab === 'info' ? 'bg-[#11024D] text-white' : 'bg-gray-200'}`}
            onClick={() => setSelectedTab('info')}
          >
            Class Info
          </button>
          <button
            className={`px-4 py-2 rounded ${selectedTab === 'subjectClasses' ? 'bg-[#11024D] text-white' : 'bg-gray-200'}`}
            onClick={() => setSelectedTab('subjectClasses')}
          >
            Subject Classes
          </button>
          <button
            className={`px-4 py-2 rounded ${selectedTab === 'students' ? 'bg-[#11024D] text-white' : 'bg-gray-200'}`}
            onClick={() => setSelectedTab('students')}
          >
            Students
          </button>
        </div>
        {selectedTab === 'info' && renderInfo()}
        {selectedTab === 'subjectClasses' && renderSubjectClasses()}
        {selectedTab === 'students' && renderStudents()}
      </div>

      {/* Add Student Modal */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-md">
            <h3 className="text-2xl font-bold text-[#11024D] mb-4">Add Student</h3>
            <input
              type="text"
              value={newStudentRegNumber}
              onChange={(e) => setNewStudentRegNumber(e.target.value)}
              placeholder="Enter Student Registration Number"
              className="border rounded px-4 py-2 mb-4 w-full"
            />
            <div className="flex justify-end">
              <button
                className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600"
                onClick={handleAddStudent}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Delete Student Modal */}
      {showDeleteModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-md">
            <h3 className="text-2xl font-bold text-[#11024D] mb-4">Confirm Deletion</h3>
            <p>Are you sure you want to remove {studentToDelete.Name} from this class?</p>
            <div className="flex justify-end mt-4">
              <button
                className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                onClick={handleDeleteStudent}
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewClass;
