import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../context/api';

const Homepage = () => {
  const [students, setStudents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [limit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    fetchStudents();
  }, [limit, offset]);

  const fetchStudents = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/students/get-all-students`, {
        params: { limit, offset }
      });
      setStudents(response.data.students || []);
      setTotal(response.data.total || 0);
    } catch (error) {
      console.error('Error fetching students:', error);
    }
  };

  const handleSearch = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/search/${searchTerm}`);
      setStudents(response.data || []);
      setTotal(response.data.total || 0);
    } catch (error) {
      console.error('Error searching students:', error);
    }
  };

  const handleViewStudent = (regNumber) => {
    navigate(`/view-student/${regNumber}`);
  };

  const handleNextPage = () => {
    if (offset + limit < total) {
      setOffset(offset + limit);
    }
  };

  const handlePreviousPage = () => {
    if (offset - limit >= 0) {
      setOffset(offset - limit);
    }
  };

  const handleCreateStudent = () => {
    navigate('/create-student');
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-3xl font-bold text-[#11024D]">Students</h2>
        <div className="flex space-x-4">
          <input
            type="text"
            placeholder="Search..."
            className="border p-2 rounded"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button
            className="bg-[#11024D] text-white px-4 py-2 rounded hover:bg-yellow-500"
            onClick={handleSearch}
          >
            Search
          </button>
          <button
            className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600"
            onClick={handleCreateStudent}
          >
            Add Student
          </button>
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-[#11024D]">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                Surname
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                Reg Number
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                Gender
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {students.map((student) => (
              <tr key={student.RegNumber}>
                <td className="px-6 py-4 whitespace-nowrap">{student.Name}</td>
                <td className="px-6 py-4 whitespace-nowrap">{student.Surname}</td>
                <td className="px-6 py-4 whitespace-nowrap">{student.RegNumber}</td>
                <td className="px-6 py-4 whitespace-nowrap">{student.Gender}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <button
                    className="bg-[#11024D] px-4 py-2 rounded text-white hover:bg-yellow-500"
                    onClick={() => handleViewStudent(student.RegNumber)}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between items-center mt-6">
        <button
          className="bg-gray-300 px-4 py-2 rounded disabled:opacity-50"
          onClick={handlePreviousPage}
          disabled={offset === 0}
        >
          Previous
        </button>
        <span className="text-[#11024D]">
          Page {Math.ceil(offset / limit) + 1} of {Math.ceil(total / limit)}
        </span>
        <button
          className="bg-gray-300 px-4 py-2 rounded disabled:opacity-50"
          onClick={handleNextPage}
          disabled={offset + limit >= total}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Homepage;
