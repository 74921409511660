import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../context/api';
import { useParams } from 'react-router-dom';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { FaDownload, FaExclamationCircle } from 'react-icons/fa';

const ViewClassResults = () => {
  const { classID, form } = useParams();
  const [termID, setTermID] = useState('');
  const [year, setYear] = useState('');
  const [results, setResults] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleTermChange = (event) => {
    setTermID(event.target.value);
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const fetchResults = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/getallresults`, {
        TermID: termID,
        Year: year,
        ClassID: classID,
        form: form,
      });
      setResults(response.data);
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching results:', error);
    }
  };

  const openModal = async (studentRegNumber, studentName, studentSurname) => {
    try {
      const response = await axios.post(`${BASE_URL}/getsubjectresults`, {
        termID,
        year,
        studentRegNumber,
        classID,
        form,
      });
      console.log(response.data);
      setSelectedStudent({
        ...response.data,
        RegNumber: studentRegNumber,
        Name: studentName,
        Surname: studentSurname,
      });
      setModalIsOpen(true);
    } catch (error) {
      console.error('Error fetching student results:', error);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedStudent(null);
  };

  const downloadResultsPDF = () => {
    const input = document.getElementById('modal-content');
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'PNG', 0, 0);
      pdf.save('student_results.pdf');
    });
  };

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <select value={termID} onChange={handleTermChange} className="border rounded px-4 py-2">
          <option value="" disabled>Select Term</option>
          <option value="1">Term 1</option>
          <option value="2">Term 2</option>
          <option value="3">Term 3</option>
        </select>
        <input
          type="number"
          placeholder="Enter year..."
          value={year}
          onChange={handleYearChange}
          min="2000"
          max="2100"
          className="border rounded px-4 py-2"
        />
        <button
          onClick={fetchResults}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Fetch Results
        </button>
      </div>

      {results.length === 0 ? (
        <div className="flex flex-col items-center justify-center mt-16">
          <FaExclamationCircle className="text-red-500 text-6xl mb-4" />
          <p className="text-lg font-semibold">Results for this term are not available yet.</p>
        </div>
      ) : (
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="px-6 py-3 border-b-2 border-gray-300 bg-[#11024D] text-left text-xs leading-4 text-white uppercase tracking-wider">
                Class Position
              </th>
            
              <th className="px-6 py-3 border-b-2 border-gray-300 bg-[#11024D] text-left text-xs leading-4 text-white uppercase tracking-wider">
                RegNumber
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 bg-[#11024D] text-left text-xs leading-4 text-white uppercase tracking-wider">
                Name
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 bg-[#11024D] text-left text-xs leading-4 text-white uppercase tracking-wider">
                Surname
              </th>
             
              <th className="px-6 py-3 border-b-2 border-gray-300 bg-[#11024D] text-left text-xs leading-4 text-white uppercase tracking-wider">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {results.map((result) => (
              <tr key={result.GradeLevelResultID} className="bg-pink-50">
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                  {result.ClassPosition}
                </td>
                
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                  {result.RegNumber}
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                  {result.Name}
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                  {result.Surname}
                </td>
               
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                  <button
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                    onClick={() => openModal(result.RegNumber, result.Name, result.Surname)}
                  >
                    View More
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {modalIsOpen && selectedStudent && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg w-full max-w-4xl">
            <div id="modal-content">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl">Student Results</h2>
                <FaDownload
                  onClick={downloadResultsPDF}
                  className="cursor-pointer text-green-600 hover:text-green-800"
                  size={24}
                />
              </div>
              <div className="grid grid-cols-2 gap-4 mb-4">
                <p><strong>Name:</strong> {selectedStudent.Name} {selectedStudent.Surname}</p>
                <p><strong>RegNumber:</strong> {selectedStudent.RegNumber}</p>

                <p><strong>Class Position:</strong> {selectedStudent.classPosition}</p>

                <p><strong>Year:</strong> {year}</p>
                <p><strong>Term:</strong> {termID}</p>
              </div>

              <table className="min-w-full bg-white">
                <thead>
                  <tr>
                    <th className="px-6 py-3 border-b-2 border-gray-300 bg-[#11024D] text-left text-xs leading-4 text-white uppercase tracking-wider">
                      Subject
                    </th>
                    <th className="px-6 py-3 border-b-2 border-gray-300 bg-[#11024D] text-left text-xs leading-4 text-white uppercase tracking-wider">
                      Final Mark
                    </th>
                    <th className="px-6 py-3 border-b-2 border-gray-300 bg-[#11024D] text-left text-xs leading-4 text-white uppercase tracking-wider">
                      Grade
                    </th>
                    <th className="px-6 py-3 border-b-2 border-gray-300 bg-[#11024D] text-left text-xs leading-4 text-white uppercase tracking-wider">
                      Comment
                    </th>
                    <th className="px-6 py-3 border-b-2 border-gray-300 bg-[#11024D] text-left text-xs leading-4 text-white uppercase tracking-wider">
                      Paper Marks
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {selectedStudent.subjectResults.map((subject) => (
                    <tr key={subject.ResultID}>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        {subject.SubjectName}
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        {subject.AverageMark}
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        {subject.Grade}
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        {subject.Comment}
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        {subject.PaperMarks.map((paper, idx) => (
                          <div key={idx}>
                            {paper.PaperName}: {paper.Mark}
                          </div>
                        ))}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <button onClick={closeModal} className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 mt-4">
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewClassResults;
