import React, { useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../context/api';

const CreateStudent = () => {
    const [studentData, setStudentData] = useState({
        RegNumber: '',
        Name: '',
        Surname: '',
        DateOfBirth: '',
        NationalIDNumber: '',
        Address: '',
        Gender: '',
        PasswordHash: ''
    });

    const [guardians, setGuardians] = useState([{
        Name: '',
        Surname: '',
        DateOfBirth: '',
        NationalIDNumber: '',
        Address: '',
        PhoneNumber: '',
        Gender: ''
    }]);

    const [balanceData, setBalanceData] = useState({
        form: '',
        term: '',
        year: new Date().getFullYear(),
        balance: 0.0,
        balance_type: 'DR',
        currency: 'USD'
    });

    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const handleInputChange = (e, setter) => {
        const { name, value } = e.target;
        setter(prevData => ({ ...prevData, [name]: value }));
    };

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setBalanceData(prevData => ({
            ...prevData,
            [name]: value // Make sure this handles both form and term changes
        }));
    };

    const handleGuardianChange = (index, e) => {
        const { name, value } = e.target;
        const newGuardians = guardians.slice();
        newGuardians[index][name] = value;
        setGuardians(newGuardians);
    };

    const addGuardian = () => {
        setGuardians([...guardians, {
            Name: '',
            Surname: '',
            DateOfBirth: '',
            NationalIDNumber: '',
            Address: '',
            PhoneNumber: '',
            Gender: ''
        }]);
    };

    const generateRegNumberAndPassword = () => {
        const regNumber = `R${Math.floor(10000 + Math.random() * 90000)}${String.fromCharCode(65 + Math.floor(Math.random() * 26))}`;
        setStudentData(prevData => ({
            ...prevData,
            RegNumber: regNumber,
            PasswordHash: regNumber
        }));
    };

    const clearInputs = () => {
        setStudentData({
            RegNumber: '',
            Name: '',
            Surname: '',
            DateOfBirth: '',
            NationalIDNumber: '',
            Address: '',
            Gender: '',
            PasswordHash: ''
        });
        setGuardians([{
            Name: '',
            Surname: '',
            DateOfBirth: '',
            NationalIDNumber: '',
            Address: '',
            PhoneNumber: '',
            Gender: ''
        }]);
        setBalanceData({
            form: '',
            term: '',
            year: new Date().getFullYear(),
            balance: 0.0,
            balance_type: 'DR',
            currency: 'USD'
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${BASE_URL}/students/add-student`, {
                studentData,
                guardians,
                balanceData
            });
            setShowSuccessModal(true);
        } catch (error) {
            console.error('Error creating student:', error);
            alert('Failed to create student');
        }
    };

    const handleCloseModal = () => {
        setShowSuccessModal(false);
        clearInputs();
    };

    return (
        <div className="min-h-screen bg-gray-100 p-8">
            <form className="bg-white p-8 rounded shadow-md" onSubmit={handleSubmit}>
                <h2 className="text-2xl font-bold mb-4">Create Student</h2>

                <h3 className="text-xl mb-2">Student Information</h3>
                <div className="grid grid-cols-3 gap-4 mb-4">
                    <div className="relative">
                        <label className="block text-gray-700">Registration Number</label>
                        <input
                            type="text"
                            name="RegNumber"
                            placeholder="Reg Number"
                            className="border p-2 rounded w-full"
                            value={studentData.RegNumber}
                            onChange={(e) => handleInputChange(e, setStudentData)}
                            readOnly
                            required
                        />
                        <button
                            type="button"
                            onClick={generateRegNumberAndPassword}
                            className="absolute right-2 top-7 text-blue-500"
                        >
                            🔄
                        </button>
                    </div>
                    <div>
                        <label className="block text-gray-700">Name</label>
                        <input
                            type="text"
                            name="Name"
                            placeholder="Name"
                            className="border p-2 rounded"
                            value={studentData.Name}
                            onChange={(e) => handleInputChange(e, setStudentData)}
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">Surname</label>
                        <input
                            type="text"
                            name="Surname"
                            placeholder="Surname"
                            className="border p-2 rounded"
                            value={studentData.Surname}
                            onChange={(e) => handleInputChange(e, setStudentData)}
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">Date of Birth</label>
                        <input
                            type="date"
                            name="DateOfBirth"
                            className="border p-2 rounded"
                            value={studentData.DateOfBirth}
                            onChange={(e) => handleInputChange(e, setStudentData)}
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">National ID Number</label>
                        <input
                            type="text"
                            name="NationalIDNumber"
                            placeholder="National ID Number"
                            className="border p-2 rounded"
                            value={studentData.NationalIDNumber}
                            onChange={(e) => handleInputChange(e, setStudentData)}
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">Address</label>
                        <input
                            type="text"
                            name="Address"
                            placeholder="Address"
                            className="border p-2 rounded"
                            value={studentData.Address}
                            onChange={(e) => handleInputChange(e, setStudentData)}
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">Gender</label>
                        <select
                            name="Gender"
                            className="border p-2 rounded"
                            value={studentData.Gender}
                            onChange={(e) => handleInputChange(e, setStudentData)}
                            required
                        >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </select>
                    </div>
                    <div>
                        <label className="block text-gray-700">Password</label>
                        <input
                            type="text"
                            name="PasswordHash"
                            placeholder="Password"
                            className="border p-2 rounded"
                            value={studentData.PasswordHash}
                            onChange={(e) => handleInputChange(e, setStudentData)}
                            readOnly
                            required
                        />
                    </div>
                </div>

                <h3 className="text-xl mb-2">Guardian Information</h3>
                {guardians.map((guardian, index) => (
                    <div className="grid grid-cols-3 gap-4 mb-4" key={index}>
                        <div>
                            <label className="block text-gray-700">Guardian Name</label>
                            <input
                                type="text"
                                name="Name"
                                placeholder="Name"
                                className="border p-2 rounded"
                                value={guardian.Name}
                                onChange={(e) => handleGuardianChange(index, e)}
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Guardian Surname</label>
                            <input
                                type="text"
                                name="Surname"
                                placeholder="Surname"
                                className="border p-2 rounded"
                                value={guardian.Surname}
                                onChange={(e) => handleGuardianChange(index, e)}
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Guardian Date of Birth</label>
                            <input
                                type="date"
                                name="DateOfBirth"
                                className="border p-2 rounded"
                                value={guardian.DateOfBirth}
                                onChange={(e) => handleGuardianChange(index, e)}
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Guardian National ID Number</label>
                            <input
                                type="text"
                                name="NationalIDNumber"
                                placeholder="National ID Number"
                                className="border p-2 rounded"
                                value={guardian.NationalIDNumber}
                                onChange={(e) => handleGuardianChange(index, e)}
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Guardian Address</label>
                            <input
                                type="text"
                                name="Address"
                                placeholder="Address"
                                className="border p-2 rounded"
                                value={guardian.Address}
                                onChange={(e) => handleGuardianChange(index, e)}
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Guardian Phone Number</label>
                            <input
                                type="text"
                                name="PhoneNumber"
                                placeholder="Phone Number"
                                className="border p-2 rounded"
                                value={guardian.PhoneNumber}
                                onChange={(e) => handleGuardianChange(index, e)}
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Guardian Gender</label>
                            <select
                                name="Gender"
                                className="border p-2 rounded"
                                value={guardian.Gender}
                                onChange={(e) => handleGuardianChange(index, e)}
                                required
                            >
                                <option value="">Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                        </div>
                    </div>
                ))}
                <button type="button" onClick={addGuardian} className="bg-green-500 text-white px-4 py-2 rounded mb-4">
                    Add Guardian
                </button>

                <h3 className="text-xl mb-2">Balance Information</h3>
                <div className="grid grid-cols-2 gap-4 mb-4">
                    <div>
                        <label className="block text-gray-700">Form</label>
                        <select
                            name="form"
                            className="border p-2 rounded"
                            value={balanceData.form}
                            onChange={handleFormChange}
                            required
                        >
                            <option value="">Select Form</option>
                            {[1, 2, 3, 4, 5, 6].map((form) => (
                                <option key={form} value={form}>{form}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label className="block text-gray-700">Term</label>
                        <select
                            name="term"
                            className="border p-2 rounded"
                            value={balanceData.term}
                            onChange={handleFormChange}
                            required
                        >
                            <option value="">Select Term</option>
                            {[1, 2, 3].map((term) => (
                                <option key={term} value={term}>{term}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label className="block text-gray-700">Year</label>
                        <input
                            type="number"
                            name="year"
                            placeholder="Year"
                            className="border p-2 rounded"
                            value={balanceData.year}
                            onChange={(e) => handleInputChange(e, setBalanceData)}
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">Balance</label>
                        <input
                            type="number"
                            name="balance"
                            placeholder="Enter Balance"
                            className="border p-2 rounded"
                            value={balanceData.balance}
                            onChange={(e) => handleInputChange(e, setBalanceData)}
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">Balance Type</label>
                        <select
                            name="balance_type"
                            className="border p-2 rounded"
                            value={balanceData.balance_type}
                            onChange={(e) => handleInputChange(e, setBalanceData)}
                            required
                        >
                            <option value="DR">Debit</option>
                            <option value="CR">Credit</option>
                        </select>
                    </div>
                </div>
                <button
                    type="submit"
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                    Create Student
                </button>
            </form>

            {/* Success Modal */}
            {showSuccessModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-4 rounded shadow-md">
                        <h3 className="text-xl font-semibold mb-4">Student Created Successfully</h3>
                        <div className="flex justify-end">
                            <button
                                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                                onClick={handleCloseModal}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CreateStudent;
