import React, { useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../context/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddUser = () => {
  const [newUsername, setNewUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [role, setRole] = useState('general');
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => setShowPassword(!showPassword);

  const handleAddUser = async () => {
    if (password !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }

    try {
      await axios.post(`${BASE_URL}/auth/add-user`, { username: newUsername, password, role });
      toast.success('User added successfully');
      setNewUsername('');
      setPassword('');
      setConfirmPassword('');
    } catch (error) {
      toast.error('Failed to add user');
    }
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="w-full max-w-md bg-white shadow-md rounded-lg p-8">
        <h3 className="text-2xl font-semibold text-center mb-6 text-gray-700">Add User</h3>
        <div className="mb-4">
          <label className="block mb-2 text-gray-600">Username</label>
          <input
            type="text"
            value={newUsername}
            onChange={(e) => setNewUsername(e.target.value)}
            className="p-3 border border-gray-300 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter username"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 text-gray-600">Password</label>
          <input
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="p-3 border border-gray-300 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter password"
          />
          <button type="button" onClick={toggleShowPassword} className="text-blue-500 mt-2 text-sm focus:outline-none">
            {showPassword ? "Hide" : "Show"} Password
          </button>
        </div>
        <div className="mb-4">
          <label className="block mb-2 text-gray-600">Confirm Password</label>
          <input
            type={showPassword ? "text" : "password"}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="p-3 border border-gray-300 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Confirm password"
          />
        </div>
        <div className="mb-6">
          <label className="block mb-2 text-gray-600">Role</label>
          <select value={role} onChange={(e) => setRole(e.target.value)} className="p-3 border border-gray-300 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-500">
            <option value="admin">Admin</option>
            <option value="finance">Finance</option>
            <option value="general">General</option>
          </select>
        </div>
        <button
          onClick={handleAddUser}
          className="w-full bg-blue-500 hover:bg-blue-600 text-white p-3 rounded-lg font-semibold transition duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          Add User
        </button>
        <ToastContainer />
      </div>
    </div>
  );
};

export default AddUser;
